<template>
  <div id="app">
    <section class="container mx-auto py-12">      
      <section class="py-10">
        <the-scheme></the-scheme>        
      </section>
      
    </section>    
  </div>
</template>

<script>
import TheScheme from './components/TheScheme.vue';
 
export default {
  name: "App",
  components: {   
    TheScheme,     
  },
};
</script> 
