
<template>
    <section>
  
       
        <div
            class="birthdate border-4 rounded-xl p-8 w-fit mx-auto border-red-900 flex gap-2.5 justify-center lg:justify-start flex-wrap flex-col items-center lg:items-end md:flex-row mt-4" style="border-color:#E1C5DE">
            <div class="flex flex-col gap-0.5" >
            <label>NAME</label>
            <input v-model="name" type="text" placeholder="Name" class=" p-3 rounded-full w-80 h-10 border-2 border-gray-600 w-70" style="background:transparent">
            </div>
            <div class="flex flex-col gap-0.5">
            <label>GEBURTSDATUM</label>
            <input v-model="date" type="date" class="p-2 rounded-full h-10 border-2 border-gray-600 w-80" style="background:transparent">
            </div>
           
            <div class=" birthdate-item flex flex-col gap-2 mt-4 md:mt-0">
                <button @click="Calculat" 
                    class="flex justify-center items-center text-gray-900 px-4  font-bold rounded-full h-10 " style="background:#FDF7A9">Berechnen</button>
            </div>

        </div>
        <div  class="container mx-auto text-center pb-12 text-xl ">
            <p>Name: {{ name }}</p>
            <p>Alter: {{ age }}</p>

            <div class="mx-auto w-fit px-5 py-3 uppercase mt-8 rounded-full" style="background:#E1C5DE">Persönliche Berechnung</div>
        </div>
        <div  class="container justify-center flex flex-wrap gap-8 lg:gap-0 items-start flex-row-reverse">
            <div class="obch w-full relative ">
                <img src="https://wordpresstuts.com.br/wp-content/uploads/2024/05/schema-6.png" class="transimage" id="schemaImg">
                <div id="n1" class="trans" style="position:absolute; width:9%; height:9%; left:1%; top:44.6%;">
                    
                    <div style="background:#A068E7; width:100%; height:100%;">
                        <span style=" color:#fff;">{{ n1
                        }}</span>
                    </div>
                </div>

                <div id="n3" class="trans" style="position:absolute; width:9%; height:9%; left:45%; top:0.8%;">
                     
                    <div style="background:#A068E7; width:100%; height:100%;">
                        <span style=" color:#fff;">{{ n3
                        }}</span>
                    </div>
                </div>

                <div id="n5" class="trans" style="position:absolute; width:9%; height:9%; left:89%; top:44.6%;">
                    
                    <div style="background:#F5604B; width:100%; height:100%;">
                        <span style=" color:#fff;">{{ n5
                        }}</span>
                    </div>
                </div>

                <div id="n7" class="trans" style="position:absolute; width:9%; height:9%; left:45%; top:88%;">
                     
                    <div style="background:#F5604B; width:100%; height:100%;">
                        <span style="color:#fff;">{{ n7
                        }}</span>
                    </div>
                </div>

                <div id="n30" class="trans relative" style="position:absolute; width:9%; height:9%; left:45%; top:45%;">
                   
                    <div style="background:#FBF16E; width:100%; height:100%;">
                        <span style="">{{
                            n30 }}</span>
                    </div>
                </div>

                <div id="n2" class="trans border-2 border-red-200"
                    style="position:absolute; width:9%; height:9%; left:14%; top:13.5%;  " >
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n2 }}</span>
                    </div>
                </div>

                <div id="n4" class="trans border-2 border-red-200"
                    style="position:absolute; width:9%; height:9%; left:76%; top:13.5%;  " >
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n4 }}</span>
                    </div>
                </div>

                <div id="n6" class="trans  border-2 border-red-200"
                    style="position:absolute; width:9%; height:9%; left:76%; top:75.5%;  " >
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n6 }}</span>
                    </div>
                </div>

                <div id="n8" class="trans  border-2 border-red-200"
                    style="position:absolute; width:9%; height:9%; left:14.4%; top:75.5%;  " >
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n8 }}</span>
                    </div>
                </div>

                <div id="n23" class="trans" style="position:absolute; width:4.5%; height:4.5%; left:47.3%; top:76.3%; ">
                    
                    <div style="background:#FFD62D; width:100%; height:100%;">
                        <span style="color:#fff;">{{ n15
                        }}</span>
                    </div>
                </div>

                <div id="n21" class="trans" style="position:absolute; width:4.5%; height:4.5%; left:77%; top:46.8%;  ">
                     
                    <div style="background:#FFD62D; width:100%; height:100%;">
                        <span style="color:#fff;">{{ n21
                        }}</span>
                    </div>
                </div>

                <div id="n28" class="trans  border-2 border-red-200"
                    style="position:absolute; width:4.5%; height:4.5%; left:61%; top:60.5%;  background:#F6EBE7;;">
                     
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n28 }}</span>
                    </div>
                </div>

                <div id="n29" class="trans  border-2 border-red-200"
                    style="position:absolute; width:4.5%; height:4.5%; left:58.5%; top:66.5%; background:#F6EBE7;;   ">
                     
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n29 }}</span>
                    </div>
                </div>

                <div id="n17" class="trans" style="position:absolute; width:4.5%; height:4.5%; left:17.5%; top:46.8%; ">
                    
                    <div style="background:#8ED9F9; width:100%; height:100%;">
                        <span style=" color:#fff;">{{ n17
                        }}</span>
                    </div>
                </div>

                <div id="n19" class="trans" style="position:absolute; width:4.5%; height:4.5%; left:47.2%; top:17.5%;">
                    
                    <div style="background:#8ED9F9; width:100%; height:100%;">
                        <span style="color:#fff;">{{ n19
                        }}</span>
                    </div>
                </div>

                <div id="n15" class="trans  border-2 border-red-200"
                    style="position:absolute; width:5.5%; height:5.5%; left:46.9%; top:82%;   background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n23 }}</span>
                    </div>
                </div>

                <div id="n11" class="trans" style="position:absolute; width:5.5%; height:5.5%; left:46.7%; top:11%; ">
                     
                    <div style="background:#326FE7; width:100%; height:100%;">
                        <span style=" color:#fff;">{{ n11
                        }}</span>
                    </div>
                </div>

                <div id="n13" class="trans  border-2 border-red-200"
                    style="position:absolute; width:5.5%; height:5.5%; left:82.8%; top:46.5%;  background:#F6EBE7;;   ">
                     
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n13 }}</span>
                    </div>
                </div>

                <div id="n25" class="trans" style="position:absolute; width:4.5%; height:4.5%; left:32%; top:46.6%;">
                    <div style="background:#83EB81; width:100%; height:100%;">
                        <span style=" color:#fff">{{ n25
                        }}</span>
                    </div>
                </div>

                <div id="n26" class="trans" style="position:absolute; width:4.5%; height:4.5%; left:47.3%; top:31%;">
                    <div style="background:#83EB81; width:100%; height:100%;">
                        <span style="color:#fff;">{{ n26
                        }}</span>
                    </div>
                </div>

                <div id="n9" class="trans" style="position:absolute; width:5.5%; height:5.5%; left:10.9%; top:46.1%;">
                     
                    <div style="background:#326FE7; width:100%; height:100%;">
                        <span style=" color:#fff;">{{ n9
                        }}</span>
                    </div>
                </div>

                <div id="n18" class="trans  border-2 border-red-200"
                    style="position:absolute; width:4.5%; height:4.5%; left:26.8%; top:26.2%; background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n18 }}</span>
                    </div>
                </div>

                <div id="n10" class="trans  border-2 border-red-200"
                    style="position:absolute; width:5.5%; height:5.5%; left:21.9%; top:21.9%; background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n10 }}</span>
                    </div>
                </div>

                <div id="n20" class="trans  border-2 border-red-200"
                    style="position:absolute; width:4.5%; height:4.5%; left:68.8%; top:26.2%; background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n20 }}</span>
                    </div>
                </div>

                <div id="n12" class="trans  border-2 border-red-200"
                    style="position:absolute; width:5.5%; height:5.5%; left:72.5%; top:21.9%;  background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n12 }}</span>
                    </div>
                </div>

                <div id="n22" class="trans  border-2 border-red-200"
                    style="position:absolute; width:4.5%; height:4.5%; left:68.3%; top:67.9%; background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n22 }}</span>
                    </div>
                </div>

                <div id="n14" class="trans  border-2 border-red-200"
                    style="position:absolute; width:5.5%; height:5.5%; left:72%; top:71.7%; background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%; ">
                        <span style="">{{ n14 }}</span>
                    </div>
                </div>

                <div id="n24" class="trans  border-2 border-red-200"
                    style="position:absolute; width:4.5%; height:4.5%; left:26.8%; top:67.9%;   background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n24 }}</span>
                    </div>
                </div>

                <div id="n16" class="trans  border-2 border-red-200"
                    style="position:absolute; width:5.5%; height:5.5%; left:21.9%; top:71.4%;  background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        <span style="">{{ n16 }}</span>
                    </div>
                </div>

                <div id="n27" class="trans  border-2 border-red-200"
                    style="position:absolute; width:4.5%; height:4.5%; left:67%; top:58.5%; background:#F6EBE7;;   ">
                    <div style="background:#F6EBE7;; width:100%; height:100%;">
                        
                        <span style="">{{ n27 }}</span>
                    </div>
                </div>

                <div id="n32" class="trans border-2 border-red-200"
                    style="position:absolute; width:5.5%; height:5.5%; left:61%; top:46.9%;  background:#F6EBE7;;    ">
                    <div style=" width:100%; height:100%; "><span
                            style=" "> {{ n32 }}
                        </span>
                    </div>
                </div>


                <div id="n31" class="trans border-2 border-red-200"
                    style="position:absolute; width:6.5%; height:6.5%; left:54.3%; top:46.1%;  background:#F6EBE7;;    ">
                     
                    <div style=" width:100%; height:100%; ">
                        <span style=" ">{{ n31
                            }}</span>
                    </div>
                </div>


                <!-- 0 - 10 -->
                <div class="trans " style="position:absolute; top:29%; left:3.1%; height:3%; width:5%;">
                    <span id="y1">{{ y1 }}</span>
                </div>
                <div class="trans  " style="position:absolute; top:36%; left:0.5%; height:3%; width:5%;">
                    <span id="y2">{{ y2 }}</span>
                </div>
                <div class="trans  " style="position:absolute; top:39%; left:-.7%; height:3%; width:5%;">
                    <span id="y3">{{ y3 }}</span>
                </div>
                <div class="trans  " style="position:absolute; top:32.5%; left:1.9%; height:3%; width:5%;">
                    <span id="y4">{{ y4 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:23.5%; left:5.8%; height:3%; width:5%;">
                    <span id="y5">{{ y5 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:20.6%; left:7.3%; height:3%; width:5%;">
                    <span id="y6">{{ y6 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:26.5%; left:4.4%; height:3%; width:5%;">
                    <span id="y7">{{ y7 }}</span>
                </div>
                <!-- 10 - 20 -->
                <div class="trans " style="position:absolute; top:3.3%; left:29.7%; height:3%; width:5%;">
                    <span id="y8">{{ y8 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:6.1%; left:23.1%; height:3%; width:5%;">
                    <span id="y9">{{ y9 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:7.6%; left:20%; height:3%; width:5%;">
                    <span id="y10">{{ y10 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:4.8%; left:26.5%; height:3%; width:5%;">
                    <span id="y11">{{ y11 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:1.1%; left:36%; height:3%; width:5%;">
                    <span id="y12">{{ y12 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:-.3%; left:39.5%; height:3%; width:5%;">
                    <span id="y13">{{ y13 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:2.3%; left:33%; height:3%; width:5%;">
                    <span id="y14">{{ y14 }}</span>
                </div>
                <!-- 20 - 30 -->
                <div class="trans " style="position:absolute; top:3.4%; left:65.5%; height:3%; width:5%;">
                    <span id="y15">{{ y15 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:1.1%; left:59.7%; height:3%; width:5%;">
                    <span id="y16">{{ y16 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:0.1%; left:55.3%; height:3%; width:5%;">
                    <span id="y17">{{ y17 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:2.3%; left:63%; height:3%; width:5%;">
                    <span id="y18">{{ y18 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:6.2%; left:71.3%; height:3%; width:5%;">
                    <span id="y19">{{ y19 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:5.1%; left:68.5%; height:3%; width:5%;">
                    <span id="y20">{{ y20 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:7.1%; left:74.6%; height:3%; width:5%;">
                    <span id="y21">{{ y21 }}</span>
                </div>
                <!-- 30 - 40 -->
                <div class="trans " style="position:absolute; top:29.4%; left:91.6%; height:3%; width:5%;">
                    <span id="y22">{{ y22 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:23.3%; left:89.0%; height:3%; width:5%;">
                    <span id="y23">{{ y23 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:20.1%; left:87.2%; height:3%; width:5%;">
                    <span id="y24">{{ y24 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:26.1%; left:90.0%; height:3%; width:5%;">
                    <span id="y25">{{ y25 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:35.5%; left:94%; height:3%; width:5%;">
                    <span id="y26">{{ y26 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:39.2%; left:95.3%; height:3%; width:5%;">
                    <span id="y27">{{ y27 }}</span>
                </div>
                <div class="trans " style="position:absolute; top:32.5%; left:92.6%; height:3%; width:5%;">
                    <span id="y28">{{ y28 }}</span>
                </div>
                <!-- 40 - 50 -->
                <!-- 40 - 50 -->
                <div class="trans " style="position:absolute; top:65%; left:91.5%; height:3%; width:5%;">
                    <span style="" id="y29">{{ y29
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:59%; left:93.5%; height:3%; width:5%;">
                    <span style="" id="y30">{{ y30
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:62%; left:92.5%; height:3%; width:5%;">
                    <span style="" id="y31">{{ y31
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:55.6%; left:95%; height:3%; width:5%;">
                    <span style="" id="y32">{{ y32
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:71.5%; left:88%; height:3%; width:5%;">
                    <span style="" id="y33">{{ y33
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:74.4%; left:87%; height:3%; width:5%;">
                    <span style="" id="y34">{{ y34
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:68.3%; left:90%; height:3%; width:5%;">
                    <span style="" id="y35">{{ y35
                    }}</span>
                </div>
                <!-- 50 - 60 -->
                <div class="trans " style="position:absolute; top:91%; left:65%; height:3%; width:5%;">
                    <span style="" id="y36">{{ y36
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:88%; left:71.7%; height:3%; width:5%;">
                    <span style="" id="y37">{{ y37
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:87%; left:75.0%; height:3%; width:5%;">
                    <span style="" id="y38">{{ y38
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:89.5%; left:68.7%; height:3%; width:5%;">
                    <span style="" id="y39">{{ y39
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:93.5%; left:59%; height:3%; width:5%;">
                    <span style="" id="y40">{{ y40
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:92%; left:62%; height:3%; width:5%;">
                    <span style="" id="y41">{{ y41
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:95%; left:55%; height:3%; width:5%;">
                    <span style="" id="y42">{{ y42
                    }}</span>
                </div>
                <!-- 60 - 70 -->
                <div class="trans " style="position:absolute; top:91%; left:30%; height:3%; width:5%;">
                    <span style="" id="y43">{{ y43
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:93.5%; left:36.5%; height:3%; width:5%;">
                    <span style="" id="y44">{{ y44
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:95%; left:39.5%; height:3%; width:5%;">
                    <span style="" id="y45">{{ y45
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:92.3%; left:33.3%; height:3%; width:5%;">
                    <span style="" id="y46">{{ y46
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:88.5%; left:23.5%; height:3%; width:5%;">
                    <span style="" id="y47">{{ y47
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:90%; left:26.5%; height:3%; width:5%;">
                    <span style="" id="y48">{{ y48
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:87%; left:20.5%; height:3%; width:5%;">
                    <span style="" id="y49">{{ y49
                    }}</span>
                </div>
                <!-- 70 - 80 -->
                <div class="trans " style="position:absolute; top:65.3%; left:3.5%; height:3%; width:5%;">
                    <span style="" id="y50">{{ y50
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:72%; left:6.5%; height:3%; width:5%;">
                    <span style="" id="y51">{{ y51
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:75%; left:7.9%; height:3%; width:5%;">
                    <span style="" id="y52">{{ y52
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:68.5%; left:5.1%; height:3%; width:5%;">
                    <span style="" id="y53">{{ y53
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:59%; left:1%; height:3%; width:5%;">
                    <span style="" id="y54">{{ y54
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:56%; left:0%; height:3%; width:5%;">
                    <span style="" id="y55">{{ y55
                    }}</span>
                </div>
                <div class="trans " style="position:absolute; top:62%; left:2.2%; height:3%; width:5%;">
                    <span style="" id="y56">{{ y56
                    }}</span>
                </div>

            </div>

        
                <div class=" w-full lg:w-2/3">

                <div class="flex flex-wrap justify-center  mt-12 px-12 lg:px-2">

                    <div class="flex gap-4  w-full  mb-4 items-center flex-wrap">
                        <p>Ahnenprogramme aus der väterlichen Linie:</p>

                        <div class="flex gap-0.5" >
                            <div class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n61">{{ n2 }}</div>
                            <div class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n61">{{ n6 }}</div>
                            <div class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n64">{{ n64 }}</div>
                        </div>
                    </div>

                    <div class="flex gap-4  w-full mb-4 items-center flex-wrap">
                        <p>Ahnenprogramme aus der mütterlichen Linie:</p>
                        <div class="flex gap-0.5" >
                            <div class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n61">{{ n4 }}</div>
                            <div class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n61">{{ n8 }}</div>
                            <div class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n61">{{ n65 }}</div>
                        </div>
                    </div>
            <div class="flex items-start justify-center flex-wrap mt-12 gap-4 lg:gap-0">
                    <div class="flex flex-col gap-6 w-full lg:w-1/3 justify-between">

                        <div class="flex items-center flex-col ">
                            <div class="rounded-full py-3 px-4" style="font-size:17px;   margin-bottom:5px; text-transform: uppercase; background:#FDF7A9;">Persönliche Zweck
                            </div>
                            <p style="font-size:13px; " class="text-center">Der persönliche Zweck eines Menschen
entsteht durch die Kombination von
Materiellem und Spirituellem.</p>
                        </div>
                        <div class="flex justify-center gap-2.5 items-center">
                            <div>
                                <div class="flex items-center justify-between gap-2.5">
                                    <p>Himmel </p>
                                    <span class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n57">{{ n57 }}</span>
                                </div>
                                <div class="flex items-center justify-between gap-2.5">
                                    <p>Erde</p>
                                    <span class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n59">{{ n59 }}</span>
                                </div>
                            </div>

                            <img src="https://wordpresstuts.com.br/wp-content/uploads/2024/05/curlybraces.png" style="width:50px" alt="curly" />
                            <div class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n58">{{ n58 }}</div>
                        </div>
                    </div>

                    <div class="flex flex-col gap-6 w-full lg:w-1/3 justify-between">
                        <div class="flex items-center flex-col ">
                            <div class="rounded-full py-3 px-4" style="font-size:17px;   margin-bottom:5px;text-transform: uppercase; background:#F3BBC8">Sozialer Zweck
                            </div>
                            <p style="font-size:13px;" class="text-center">Themen der Sozial- und Familiensysteme: Ergebnisse und Anerkennung in der Gesellschaft</p>
                        </div>
                        <div class="flex justify-center gap-2.5 items-center">
                            <div>
                                <div class="flex items-center justify-between gap-2.5">
                                    <p>M</p>
                                    <span class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n60">{{ n60 }}</span>
                                </div>
                                <div class="flex items-center justify-between gap-2.5">
                                    <p>W</p>
                                    <span class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n62">{{ n62 }}</span>
                                </div>
                            </div>
                            <img src="https://wordpresstuts.com.br/wp-content/uploads/2024/05/curlybraces.png" style="width:50px" alt="curly" />
                            <div class="round trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center" id="n61">{{ n61 }}</div>
                        </div>
                    </div>

                    <div class="flex flex-wrap lg:flex-nowrap gap-2.5  w-full lg:w-1/3">
                        <div class="flex flex-col gap-2.5 w-full">
                            <div class="flex gap-2.5 items-center justify-center">
                                <span class="rounded-full py-3 px-4" style="font-size:17px; text-transform: uppercase; background:#E1C5DE">Spirituelle Zweck</span>
                                
                            </div>
                            <p style="font-size:13px;  " class="text-center">
                                Welche spirituelle Absicht hinter deiner Inkarnation steckt und im Laufe des Lebens gesammelte Erfahrungen.
                            </p>
                            <span class="round  trans rounded-full border-2 w-12 h-12 border-gray-900 flex justify-center items-center mx-auto" id="n63">{{ n63 }}</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
 
        <div class="container mx-auto pt-12 flex flex-col justify-center items-center">
        <div class="w-full  text-gray-900 pr-0 lg:pr-10">
                 
                 <p class="mb-3 text-xl text-center lg:text-left uppercase w-fit py-3 px-4 rounded-full" style="background:#FDF7A9;">Gesundheitskarte</p>
                 <table class="table " width="100%">
                     <tbody>
                         <tr class="noshaddow text-gray-500 p-2 lg:p-3 h-14 font-bold"  >
                             
                             <td class="pl-4 explanation-item" style="padding-left:20px;">Chakra</td>
                             <td style="text-align: center;"> Physisch </td>
                             <td style="text-align: center;"> Energie </td>
                             <td style="text-align: center;"> Emotionen </td>
 
                         </tr>
                         <tr class="   " >
                              
                             <td class="trans" id="chakra7"><span class="td_chakra p-2 lg:p-3"
                                     style="display:flex;gap:15px;align-items:center;">
                                     <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;"><span
                                             style="font-size:16px;font-weight:bold;color:#A068E7">Kronenchakra</span> </div>
                                 </span></td>
                             <td class="trans" id="n33" style="text-align:center">{{ n33 }}
                             </td>
                             <td class="trans" id="n34" style="text-align:center">{{ n34 }}
                             </td>
                             <td class="trans" id="n35" style="text-align:center">{{ n35 }}
                             </td>
                         </tr>
                         <tr class="  p-2 lg:p-3" >
                             
                             <td class="trans" id="chakra6"><span class="td_chakra p-2 lg:p-3"
                                     style="display:flex;gap:15px;align-items:center;">
                                     <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;"><span
                                             style="font-size:16px;font-weight:bold;color:#326FE7">Stirnchakra</span> </div>
                                 </span></td>
                             <td class="trans" id="n36" style="text-align:center">{{ n36 }}
                             </td>
                             <td class="trans" id="n37" style="text-align:center">{{ n37 }}
                             </td>
                             <td class="trans" id="n38" style="text-align:center">{{ n38 }}
                             </td>
                         </tr>
                         <tr class="  p-2 lg:p-3">
                              
                             <td class="trans" id="chakra5"><span class="td_chakra p-2 lg:p-3"
                                     style="display:flex;gap:15px;align-items:center;">
                                     <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;"><span
                                             style="font-size:16px;font-weight:bold;color:#8ED9F9">Halschakra</span> </div>
                                 </span></td>
                             <td class="trans" id="n39" style="text-align:center">{{ n39 }}
                             </td>
                             <td class="trans" id="n40" style="text-align:center">{{ n40 }}
                             </td>
                             <td class="trans" id="n41" style="text-align:center">{{ n41 }}
                             </td>
                         </tr>
                         <tr class="  p-2 lg:p-3" >
                              
                             <td class="trans" id="chakra4"><span class="td_chakra p-2 lg:p-3"
                                     style="display:flex;gap:15px;align-items:center;">
                                     <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;"><span
                                             style="font-size:16px;font-weight:bold;color:#83EB81">Herzchakra</span> </div>
                                 </span></td>
                             <td class="trans" id="n42" style="text-align:center">{{ n42 }}
                             </td>
                             <td class="trans" id="n43" style="text-align:center">{{ n43 }}
                             </td>
                             <td class="trans" id="n44" style="text-align:center">{{ n44 }}
                             </td>
                         </tr>
                         <tr class="  p-2 lg:p-3" >
                             
                             <td class="trans" id="chakra3"><span class="td_chakra p-2 lg:p-3"
                                     style="display:flex;gap:15px;align-items:center;">
                                     <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;"><span
                                             style="font-size:16px;font-weight:bold;color:#FBF16E">Solarplexus</span> </div>
                                 </span></td>
                             <td class="trans" id="n45" style="text-align:center">{{ n45 }}
                             </td>
                             <td class="trans" id="n46" style="text-align:center">{{ n46 }}
                             </td>
                             <td class="trans" id="n47" style="text-align:center">{{ n47 }}
                             </td>
                         </tr>
                         <tr class="  p-2 lg:p-3" >
                              
                             <td class="trans" id="chakra2"><span class="td_chakra p-2 lg:p-3"
                                     style="display:flex;gap:15px;align-items:center;">
                                     <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;"><span
                                             style="font-size:16px;font-weight:bold;color:#FFD62D">Sakralchakra</span> </div>
                                 </span></td>
                             <td class="trans " id="n48" style="text-align:center">{{ n48 }}
                             </td>
                             <td class="trans" id="n49" style="text-align:center">{{ n49 }}
                             </td>
                             <td class="trans" id="n50" style="text-align:center">{{ n50 }}
                             </td>
                         </tr>
                         <tr class="  p-2 lg:p-3" >
                              
                             <td class="trans" id="chakra1"><span class="td_chakra p-2 lg:p-3"
                                     style="display:flex;gap:15px;align-items:center;">
                                     <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;"><span
                                             style="font-size:16px;font-weight:bold;color:#F5604B">Wurzelchakra</span> </div>
                                 </span></td>
                             <td class="trans" id="n51" style="text-align:center">{{ n51 }}
                             </td>
                             <td class="trans" id="n52" style="text-align:center">{{ n52 }}
                             </td>
                             <td class="trans" id="n53" style="text-align:center">{{ n53 }}
                             </td>
                         </tr>
                         <tr class=" noshaddow p-2 pb-4 h-14"  >
                              
                             <td class="trans" id="chakra"><span class="td_chakra p-2 lg:p-3 text-gray-500"
                                     style="display:flex;gap:15px;align-items:center;">
                                     <div style="display:flex;flex-direction:column;gap:5px;align-items:flex-start;"><span
                                             style="font-size:16px;font-weight:bold">
                                             Ergebnis</span> </div></span></td>
                             <td class="trans" id="n54" style="text-align:center">{{ n54 }}
                             </td>
                             <td class="trans" id="n55" style="text-align:center">{{ n55 }}
                             </td>
                             <td class="trans" id="n56" style="text-align:center">{{ n56 }}
                             </td>
                         </tr>
                     </tbody>
                 </table>
             </div>
            </div>
    </section>
</template>

<script>
 
// import {useDark, useToggle} from "@vueuse/core";

 
export default {

    data() {
        return { 
             
            schema: "schema.png", 
            name: '',
            age: '',
            date: '----------',
            resultDay: 0,
            resultMonth: 0,
            resultYear: 0,
            open: false,
            open1: false,
            open2: false,
            open3: false,
            open4: false,
            open5: false,
            open6: false,
            open7: false,
            open8: false,
             
            result: '',
            isRestult: false,
            showResult: false,
            values: 0,
            day: 2,
            month: 4,
            year: 1981,
            n1: 0,
            n3: 0,
            n5: 0,
            n2: 0,
            n4: 0,
            n6: 0,
            n7: 0,
            n8: 0,
            n9: 0,
            n10: 0,
            n11: 0,
            n12: 0,
            n13: 0,
            n14: 0,
            n15: 0,
            n16: 0,
            n17: 0,
            n18: 0,
            n19: 0,
            n20: 0,
            n21: 0,
            n22: 0,
            n23: 0,
            n24: 0,
            n25: 0,
            n26: 0,
            n27: 0,
            n28: 0,
            n29: 0,
            n30: 0,
            n31:0,
            n32:0,
            y1: 0,
            y2: 0,
            y3: 0,
            y4: 0,
            y5: 0,
            y6: 0,
            y7: 0,
            y8: 0,
            y9: 0,
            y10: 0,
            y11: 0,
            y12: 0,
            y13: 0,
            y14: 0,
            y15: 0,
            y16: 0,
            y17: 0,
            y18: 0,
            y19: 0,
            y20: 0,
            y21: 0,
            y22: 0,
            y23: 0,
            y24: 0,
            y25: 0,
            y26: 0,
            y27: 0,
            y28: 0,
            y29: 0,
            y30: 0,
            y31: 0,
            y32: 0,
            y33: 0,
            y34: 0,
            y35: 0,
            y36: 0,
            y37: 0,
            y38: 0,
            y39: 0,
            y40: 0,
            y41: 0,
            y42: 0,
            y43: 0,
            y44: 0,
            y45: 0,
            y46: 0,
            y47: 0,
            y48: 0,
            y49: 0,
            y50: 0,
            y51: 0,
            y52: 0,
            y53: 0,
            y54: 0,
            y55: 0,
            y56: 0,
            n33: 0,
            n34: 0,
            n35: 0,
            n36: 0,
            n37: 0,
            n38: 0,
            n39: 0,
            n40: 0,
            n41: 0,
            n42: 0,
            n43: 0,
            n44: 0,
            n45: 0,
            n46: 0,
            n47: 0,
            n48: 0,
            n49: 0,
            n50: 0,
            n51: 0,
            n52: 0,
            n53: 0,
            n54: 0,
            n55: 0,
            n56: 0,
            n57: 0,
            n58: 0,
            n59: 0,
            n60: 0,
            n61: 0,
            n62: 0,
            n63: 0,
            n64:0,
            n65:0
        }
    },
methods: {
  
    

        Age(){
    var today = new Date();
    var birthDate = new Date(this.date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    this.age = age;
},

IfIsMoreThan22(value) {
            if (value > 22) {
                this.values = value.toString().split('').map(Number);
                value = this.values.reduce((a, b) => b + a);

                if (value < 22) {
                    return value;
                } else {

                    if(value == 22){
                        return value
                    }else {
                        this.values = value.toString().split('').map(Number);
                    value = this.values.reduce((a, b) => b + a);
                    return value;
                    }
                   
                }

            } else {
                return value;
            }
        },
        TheN1() {
            this.resultDay = this.date.split('-');
            this.day = parseInt(this.resultDay[2]);
            this.n1 = this.IfIsMoreThan22(this.day);
        },

        TheN5() {
            this.resultYear = this.date.split('-');
            this.year = parseInt(this.resultYear[0]);
            this.n5 = this.IfIsMoreThan22(this.year);
        },

        TheN3() {
            this.resultMonth = this.date.split('-');
            this.month = parseInt(this.resultMonth[1]);
            this.n3 = this.IfIsMoreThan22(this.month);
        },

        TheN7() {
            this.n7 = this.IfIsMoreThan22(this.n1 + this.n5 + this.n3);
        },

        TheD() {
            this.n30 = this.IfIsMoreThan22(this.n1 + this.n5 + this.n3 + this.n7);
        },
        TheN17() {

            this.n17 = this.IfIsMoreThan22(this.n30 + this.n1);
        },
        TheN19() {
            this.n19 = this.IfIsMoreThan22(this.n3 + this.n30);
        },

        TheN21() {
            this.n21 = this.IfIsMoreThan22(this.n5 + this.n30);
        },



        TheN9() {
            this.n9 = this.IfIsMoreThan22(this.n1 + this.n17);
        },

        TheN11() {
            this.n11 = this.IfIsMoreThan22(this.n19 + this.n3);
        },
        TheN13() {
            this.n13 = this.IfIsMoreThan22(this.n5 + this.n21);
        },



        TheN2() {
            this.n2 = this.IfIsMoreThan22(this.n1 + this.n3);
        },

        TheN4() {
            this.n4 = this.IfIsMoreThan22(this.n5 + this.n3);
        },

        TheN6() {
            this.n6 = this.IfIsMoreThan22(this.n5 + this.n7);
        },

        TheN8() {
            this.n8 = this.IfIsMoreThan22(this.n1 + this.n7);
        },

        TheN26() {
            this.n26 = this.IfIsMoreThan22(this.n19 + this.n30);
        },

        TheN25() {
            this.n25 = this.IfIsMoreThan22(this.n17 + this.n30);
        },


        TheN31() {
            this.n31 = this.IfIsMoreThan22(this.n2 + this.n4 + this.n6 + this.n8);
        },

        TheN32() {
            this.n32 = this.IfIsMoreThan22(this.n30 + this.n31);
        },

        TheN18() {
            this.n18 = this.IfIsMoreThan22(this.n2 + this.n31);
        },

        TheN20() {
            this.n20 = this.IfIsMoreThan22(this.n4 + this.n31);
        },

        TheN12() {
            this.n12 = this.IfIsMoreThan22(this.n4 + this.n20);
        },

        TheN22() {
            this.n22 = this.IfIsMoreThan22(this.n6 + this.n31);
        },

        TheN14() {
            this.n14 = this.IfIsMoreThan22(this.n6 + this.n22);
        },


        TheN24() {
            this.n24 = this.IfIsMoreThan22(this.n8 + this.n31);
        },

        TheN16() {
            this.n16 = this.IfIsMoreThan22(this.n8 + this.n24);
        },



        TheN10() {
            this.n10 = this.IfIsMoreThan22(this.n2 + this.n18);
        },
        TheN15() {
            this.n15 = this.IfIsMoreThan22(this.n7 + this.n30);
        },

        TheN23() {
            this.n23 = this.IfIsMoreThan22(this.n7 + this.n15);
        },

        TheN28() {
            this.n28 = this.IfIsMoreThan22(this.n15 + this.n21);
        },


        TheN29() {
            this.n29 = this.IfIsMoreThan22(this.n28 + this.n15);
        },


        TheN27() {
            this.n27 = this.IfIsMoreThan22(this.n28 + this.n21);
        },


        //calculating years

        TheY1() {
            this.y1 = this.IfIsMoreThan22(this.n1 + this.n2);
        },

        TheY2() {
            this.y2 = this.IfIsMoreThan22(this.n1 + this.y1);
        },

        TheY3() {
            this.y3 = this.IfIsMoreThan22(this.n1 + this.y2);
        },

        TheY4() {
            this.y4 = this.IfIsMoreThan22(this.y1 + this.y2);
        },

        TheY5() {
            this.y5 = this.IfIsMoreThan22(this.y1 + this.n2);
        },

        TheY7() {
            this.y7 = this.IfIsMoreThan22(this.y1 + this.y5);
        },

        TheY6() {
            this.y6 = this.IfIsMoreThan22(this.y5 + this.n2);
        },

        TheY8() {
            this.y8 = this.IfIsMoreThan22(this.n3 + this.n2);
        },

        TheY9() {
            this.y9 = this.IfIsMoreThan22(this.y8 + this.n2);
        },

        TheY10() {
            this.y10 = this.IfIsMoreThan22(this.y9 + this.n2);
        },

        TheY11() {
            this.y11 = this.IfIsMoreThan22(this.y9 + this.y8);
        },

        TheY12() {
            this.y12 = this.IfIsMoreThan22(this.n3 + this.y8);
        },

        TheY13() {
            this.y13 = this.IfIsMoreThan22(this.month + this.y12);
        },

        TheY14() {
            this.y14 = this.IfIsMoreThan22(this.y8 + this.y12);
        },

        TheY15() {
            this.y15 = this.IfIsMoreThan22(this.month + this.n4);
        },

        TheY16() {
            this.y16 = this.IfIsMoreThan22(this.month + this.y15);
        },

        TheY17() {
            this.y17 = this.IfIsMoreThan22(this.month + this.y16);
        },

        TheY18() {
            this.y18 = this.IfIsMoreThan22(this.y15 + this.y16);
        },

        TheY19() {
            this.y19 = this.IfIsMoreThan22(this.y15 + this.n4);
        },

        TheY20() {
            this.y20 = this.IfIsMoreThan22(this.y15 + this.y19);
        },

        TheY21() {
            this.y21 = this.IfIsMoreThan22(this.n4 + this.y19);
        },

        TheY22() {
            this.y22 = this.IfIsMoreThan22(this.n5 + this.n4);
        },

        TheY23() {
            this.y23 = this.IfIsMoreThan22(this.y22 + this.n4);
        },

        TheY24() {
            this.y24 = this.IfIsMoreThan22(this.y23 + this.n4);
        },

        TheY25() {
            this.y25 = this.IfIsMoreThan22(this.y23 + this.y22);
        },

        TheY26() {
            this.y26 = this.IfIsMoreThan22(this.n5 + this.y22);
        },

        TheY27() {
            this.y27 = this.IfIsMoreThan22(this.n5 + this.y26);
        },

        TheY28() {
            this.y28 = this.IfIsMoreThan22(this.y22 + this.y26);
        },

        TheY29() {
            this.y29 = this.IfIsMoreThan22(this.n6 + this.n5);
        },

        TheY30() {
            this.y30 = this.IfIsMoreThan22(this.y29 + this.n5);
        },

        TheY31() {
            this.y31 = this.IfIsMoreThan22(this.y29 + this.y30);
        },

        TheY32() {
            this.y32 = this.IfIsMoreThan22(this.n5 + this.y30);
        },

        TheY33() {
            this.y33 = this.IfIsMoreThan22(this.n6 + this.y29);
        },

        TheY34() {
            this.y34 = this.IfIsMoreThan22(this.n6 + this.y33);
        },

        TheY35() {
            this.y35 = this.IfIsMoreThan22(this.y29 + this.y33);
        },

        TheY36() {
            this.y36 = this.IfIsMoreThan22(this.n6 + this.n7);
        },

        TheY37() {
            this.y37 = this.IfIsMoreThan22(this.n6 + this.y36);
        },

        TheY38() {
            this.y38 = this.IfIsMoreThan22(this.n6 + this.y37);
        },

        TheY39() {
            this.y39 = this.IfIsMoreThan22(this.y36 + this.y37);
        },

        TheY40() {
            this.y40 = this.IfIsMoreThan22(this.y36 + this.n7);
        },

        TheY41() {
            this.y41 = this.IfIsMoreThan22(this.y36 + this.y40);
        },

        TheY42() {
            this.y42 = this.IfIsMoreThan22(this.n7 + this.y40);
        },

        TheY43() {
            this.y43 = this.IfIsMoreThan22(this.n7 + this.n8);
        },

        TheY44() {
            this.y44 = this.IfIsMoreThan22(this.n7 + this.y43);
        },

        TheY45() {
            this.y45 = this.IfIsMoreThan22(this.n7 + this.y44);
        },

        TheY46() {
            this.y46 = this.IfIsMoreThan22(this.y43 + this.y44);
        },

        TheY47() {
            this.y47 = this.IfIsMoreThan22(this.y43 + this.n8);
        },

        TheY48() {
            this.y48 = this.IfIsMoreThan22(this.y43 + this.y47);
        },

        TheY49() {
            this.y49 = this.IfIsMoreThan22(this.n8 + this.y47);
        },

        TheY50() {
            this.y50 = this.IfIsMoreThan22(this.n8 + this.n1);
        },

        TheY51() {
            this.y51 = this.IfIsMoreThan22(this.n8 + this.y50);
        },

        TheY52() {
            this.y52 = this.IfIsMoreThan22(this.n8 + this.y51);
        },

        TheY53() {
            this.y53 = this.IfIsMoreThan22(this.y50 + this.y51);
        },

        TheY54() {
            this.y54 = this.IfIsMoreThan22(this.y50 + this.n1);
        },

        TheY55() {
            this.y55 = this.IfIsMoreThan22(this.n1 + this.y54);
        },

        TheY56() {
            this.y56 = this.IfIsMoreThan22(this.y50 + this.y54);
        },

        TheN33() {
            this.n33 = this.n1;
        },

        TheN34() {
            this.n34 = this.n3;
        },

        TheN35() {
            this.n35 = this.IfIsMoreThan22(this.n3 + this.n1);
        },

        TheN36() {
            this.n36 = this.n9;
        },

        TheN37() {
            this.n37 = this.n11;
        },

        TheN38() {
            this.n38 = this.IfIsMoreThan22(this.n9 + this.n11);
        },

        TheN39() {
            this.n39 = this.n17;
        },

        TheN40() {
            this.n40 = this.n19;
        },

        TheN41() {
            this.n41 = this.IfIsMoreThan22(this.n19 + this.n17);
        },

        TheN42() {
            this.n42 = this.n25;
        },

        TheN43() {
            this.n43 = this.n26;
        },

        TheN44() {
            this.n44 = this.IfIsMoreThan22(this.n26 + this.n25);
        },

        TheN45() {
            this.n45 = this.n30;
        },

        TheN46() {
            this.n46 = this.n30;
        },

        TheN47() {
            this.n47 = this.IfIsMoreThan22(this.n30 + this.n30);
        },

        TheN48() {
            this.n48 = this.n21;
        },

        TheN49() {
            this.n49 = this.n15;
        },

        TheN50() {
            this.n50 = this.IfIsMoreThan22(this.n21 + this.n15);
        },

        TheN51() {
            this.n51 = this.n5;
        },

        TheN52() {
            this.n52 = this.n7;
        },

        TheN53() {
            this.n53 = this.IfIsMoreThan22(this.n5 + this.n7);
        },

        TheN54() {
            this.n54 = this.IfIsMoreThan22(this.n1 + this.n9 + this.n17 + this.n25 + this.n30 + this.n21 + this.n5);
        },

        TheN55() {
            this.n55 = this.IfIsMoreThan22(this.n3 + this.n11 + this.n19 + this.n26 + this.n30 + this.n15 + this.n7);
        },

        TheN56() {
            this.n56 = this.IfIsMoreThan22(this.n35 + this.n38 + this.n41 + this.n44 +  this.n47  + this.n50 + this.n53 );
        },

        TheN57() {
            this.n57 = this.IfIsMoreThan22(this.n3 + this.n7);
        },


        TheN59() {
            this.n59 = this.IfIsMoreThan22(this.n1 + this.n5);
        },

        TheN58() {
            this.n58 = this.IfIsMoreThan22(this.n59 + this.n57);
        },


        TheN60() {
            this.n60 = this.IfIsMoreThan22(this.n2 + this.n6);
        },


        TheN62() {
            this.n62 = this.IfIsMoreThan22(this.n4 + this.n8);
        },

        TheN61() {
            this.n61 = this.IfIsMoreThan22(this.n60 + this.n62);
        },


        TheN63() {
            this.n63 = this.IfIsMoreThan22(this.n58 + this.n61);
        },

        TheN64() {
            this.n64 = this.IfIsMoreThan22(this.n2 + this.n6);
        },

        TheN65() {
            this.n65 = this.IfIsMoreThan22(this.n4 + this.n8);
        },

        Calculat() {
            this.open = false,
                this.open1 = false;
            this.open2 = false;
            this.open3 = false;
            this.open4 = false;
            this.open5 = false;
            this.open6 = false;
            this.open7 = false;
            this.open8 = false;
            //this.isRestult = true;
            this.showResult = true;
            this.TheN1();
            this.TheN5();
            this.TheN3();
            this.TheN7();
            this.TheD();
            this.TheN17();
            this.TheN19();
            this.TheN41();
            this.TheN21();
            this.TheN9();
            this.TheN11();
            this.TheN13();
            this.TheN2();
            this.TheN4();
            this.TheN6();
            this.TheN8();
            this.TheN26();
            this.TheN25();
            this.TheN31();
            this.TheN32();
            this.TheN18();
            this.TheN20();
            this.TheN12();
            this.TheN22();
            this.TheN14();
            this.TheN24();
            this.TheN16();
            this.TheN10();
            this.TheN15();
            this.TheN23();
            this.TheN28();
            this.TheN27();
            this.TheN29();
            this.TheY1();
            this.TheY2();
            this.TheY3();
            this.TheY4();
            this.TheY5();
            this.TheY7();
            this.TheY6();
            this.TheY8();
            this.TheY9();
            this.TheY10();
            this.TheY11();
            this.TheY12();
            this.TheY13();
            this.TheY14();
            this.TheY15();
            this.TheY16();
            this.TheY17();
            this.TheY18();
            this.TheY19();
            this.TheY20();
            this.TheY21();
            this.TheY22();
            this.TheY23();
            this.TheY24();
            this.TheY25();
            this.TheY26();
            this.TheY27();
            this.TheY28();
            this.TheY29();
            this.TheY30();
            this.TheY31();
            this.TheY32();
            this.TheY33();
            this.TheY34();
            this.TheY35();
            this.TheY36();
            this.TheY37();
            this.TheY38();
            this.TheY39();
            this.TheY40();
            this.TheY41();
            this.TheY42();
            this.TheY43();
            this.TheY44();
            this.TheY45();
            this.TheY46();
            this.TheY47();
            this.TheY48();
            this.TheY49();
            this.TheY50();
            this.TheY51();
            this.TheY52();
            this.TheY53();
            this.TheY54();
            this.TheY55();
            this.TheY56();
            this.TheN33();
            this.TheN34();
            this.TheN35();
            this.TheN36();
            this.TheN37();
            this.TheN38();
            this.TheN39();
            this.TheN40();
            this.TheN41();
            this.TheN42();
            this.TheN43();
            this.TheN44();
            this.TheN45();
            this.TheN46();
            this.TheN47();
            this.TheN48();
            this.TheN49();
            this.TheN50();
            this.TheN51();
            this.TheN52();
            this.TheN53();
            this.TheN54();
            this.TheN55();
            this.TheN56();
            this.TheN57();
            this.TheN59();
            this.TheN58();
            this.TheN60();
            this.TheN62();
            this.TheN61();
            this.TheN63();
            this.TheN64();
            this.TheN65();

        },
        showResultPlease() {
            if (this.email !== '') {
                this.isRestult = true;
            }
            else {
                alert('Por favor, informa seu e-mail');
            }
        },
       
        
    },
}
     
</script>

<style scoped>
@font-face {
  font-family: "BlackerSansProTextRegular";
  src: url('webFonts/BlackerSansProTextRegular/font.woff2') format('woff2'), url('webFonts/BlackerSansProTextRegular/font.woff') format('woff');
}
   
body {
    background:#F6EBE7;
    font-family: "BlackerSansProTextRegular";
}
      
.resultItem {
    background: #fafbfc;
}

.sectionopener items-center span {
    font-size: 24px;
    font-weight: bold;
}

 tr {
    border-radius:10px;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4) 100%);
    
 }
 .noshaddow {
    box-shadow:none;
 }

div,
p,
span {
    font-family: inherit;
}

.transimage {
    bottom: 7px !important;
    right: 3px !important;
}


select {
    background-color: #fff !important;
    border-radius: 26px !Important;
}

@media screen and (max-width:1200px) {
    .obch {
        flex: 0 0 100% !important;
        padding: 10px;
    }

    .transimage {
        bottom: 5px !important;
    }

}


#formMatrix h3 {
    text-align: center;
}


.obch .trans,
.obch .trans>div {
    border-radius: 50%;
    display:flex;
    justify-content:center;
    align-items:center;
}

.container {
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
}

.birthdate {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 90px;
    justify-content: center;
}

.birthdate-item {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.transimage {
    width: 102%;
    z-index: 0;
    position: relative;
    bottom: 6px;
    right: 5px;

}

.result p {
    margin-bottom: 18px;
}

.trans span {
    font-size: 14px;
}

#app td {
    padding-left:15px !important;
}

@media screen and (max-width:1335px) {
    .trans span {
        font-size: 12px !important;
    }
}

@media screen and (max-width:1200px) {
    .obch {
        flex: 0 0 100%;
        padding: 2px;
    }

    #app td {
    padding-left:12px !important;
}

    .rightblock {
        flex: 0 0 100%;
    }

    .trans span {
        font-size: 15px;
    }

}

#n1 span,
#n3 span,
#n5 span,
#n7 span,
#n30 span,
#n2 span,
#n4 span,
#n6 span,
#n8 span {
    font-size: 22px
}

@media screen and (max-width:600px) {
    .chakras>div {
        padding-left: 20px;
    }

    .trans span {
        font-size: 12px;
        position: relative;
         
    }

    

    .obch .trans span {
        font-size: 9px !important;
        
    }

    #n1 span,
    #n3 span,
    #n5 span,
    #n7 span,
    #n30 span,
    #n2 span,
    #n4 span,
    #n6 span,
    #n8 span {
        font-size: 16px;
        position: relative;
        bottom: 1px;
    }

    .transimage {
        width: 100%;
        z-index: 0;
        position: relative;
        bottom: 2px !important;
        right: 2px;
    }

   

}

table {
        margin-left: auto;
        margin-right: auto;
        margin: 0 auto;
    }

@media screen and (max-width:470px) {

    .obch .trans span {
        font-size: 9px;
         

    }

}</style>
